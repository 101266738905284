import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { SURVEY_ID_HEADER } from '../constants';
import useFetch from './useFetch';

export function useUpdateQuestion() {
  const fetcher = useFetch();
  return ({ questionId, data }) =>
    fetcher({
      path: `v1/questions/${questionId}/update/`,
      method: 'PATCH',
      data,
    });
}

export function useCodeFrames() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { questionIds, filters, surveyId }] = queryKey;
    return fetcher({
      path: `v1/questions/${questionIds}/codeframes/`,
      filters,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useCreateCodeFrames() {
  const fetcher = useFetch();
  return ({ questionId }) =>
    fetcher({
      path: `v1/questions/${questionId}/codeframes/create/`,
      method: 'POST',
    });
}

export function useRawExport() {
  const { getAccessTokenSilently } = useAuth0();
  return async ({ queryKey }) => {
    const [, { questionId, filters, surveyId }] = queryKey;
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_AUDIENCE}api/v1/questions/${questionId}/raw/${
        filters ? `?${new URLSearchParams(filters).toString()}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          [SURVEY_ID_HEADER]: surveyId,
        },
      },
    );
    if (!response.ok) {
      throw new Error('Something went wrong');
    }
    return { body: await response.blob(), headers: response.headers };
  };
}

export function useAggregateResponses() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { questionId, surveyId }] = queryKey;
    return fetcher({
      path: `v1/questions/${questionId}/responses/aggregate/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useQuestionResponses() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { questionId, filters, surveyId }] = queryKey;
    return fetcher({
      path: `v1/questions/${questionId}/responses/`,
      filters,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useSentiments() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { questionId, surveyId }] = queryKey;
    return fetcher({
      path: `v1/questions/${questionId}/sentiments/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useResponseOptions() {
  const fetcher = useFetch();
  return ({ queryKey }) => {
    const [, { questionId, surveyId }] = queryKey;
    return fetcher({
      path: `v1/questions/${questionId}/response-options/`,
      headers: { [SURVEY_ID_HEADER]: surveyId },
    });
  };
}

export function useUpdateDisplaySentiment() {
  const fetcher = useFetch();
  return ({ questionId, data }) =>
    fetcher({
      path: `v1/questions/${questionId}/update-display-sentiment/`,
      method: 'PATCH',
      data,
    });
}

export function useRemapQuestion() {
  const fetcher = useFetch();
  return ({ questionId }) =>
    fetcher({
      path: `v1/questions/${questionId}/remap/`,
      method: 'PUT',
    });
}

// CUSTOM REACT-QUERY HOOKS BELOW - WE SHOULD TRANSITION TO THESE

export function useQuestion({ questionId, surveyId }) {
  const fetcher = useFetch();
  return useQuery({
    queryKey: ['question', { questionId, surveyId }],
    queryFn: () =>
      fetcher({
        path: `v1/questions/${questionId}/`,
        headers: { [SURVEY_ID_HEADER]: surveyId },
      }),
  });
}
