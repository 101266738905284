import { EditOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, PageHeader, Row, Space, Tabs, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useInvitations } from '../../api/Invitations';
import { useOrganization, useUsers } from '../../api/Onboarding';
import BackButton from '../../components/BackButton';
import InviteButton from '../../components/InviteButton';
import InviteMemberModal from '../../components/InviteMemberModal';
import Loading from '../../components/Loading';
import { SettingsPageTabs, UserRoleType } from '../../constants';
import EditOrganizationModal from './EditOrganizationModal';
import InvitationsTab from './InvitationsTab';
import UsersTab from './UsersTab';

const { Text } = Typography;

const StyledDiv = styled.div`
  padding: 0 24px 24px;
`;

function Settings() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(SettingsPageTabs.USERS);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const { data: organization, isLoading: organizationLoading } = useQuery(
    ['organization'],
    useOrganization(),
  );
  const { data: users, isLoading: usersLoading } = useQuery(
    ['users', { orgId: organization?.data.id }],
    useUsers(),
    {
      enabled: !!organization?.data.id,
      select: (data) => ({
        ...data,
        data: data.data.filter((u) => u.role !== UserRoleType.CLIENT),
      }),
    },
  );
  const { data: invitations, isLoading: invitationsLoading } = useQuery(
    ['invitations'],
    useInvitations(),
    {
      select: (data) => ({
        ...data,
        data: data.data.filter((i) => i.role !== UserRoleType.CLIENT),
      }),
    },
  );

  const toggleInviteModal = () => {
    setShowInviteModal(!showInviteModal);
  };

  const toggleEditOrganizationModal = () => {
    setShowOrganizationModal(!showOrganizationModal);
  };

  const backNavigation = () => <BackButton onClick={() => navigate(-1)} text="Back" />;

  const tabData = [
    {
      key: SettingsPageTabs.USERS,
      label: SettingsPageTabs.USERS,
    },
    {
      key: SettingsPageTabs.INVITATIONS,
      label: SettingsPageTabs.INVITATIONS,
    },
  ];

  const onInvite = useCallback(
    (response, data) => {
      const formData = data.data;
      // put the new invitation at the top of the list
      const updatedInvitations = [
        {
          id: response.data.invitation_id,
          token: response.data.token,
          name: formData.name,
          email: formData.email,
          role: formData.role,
          is_expired: false,
        },
        ...invitations.data,
      ];
      queryClient.setQueryData(['invitations'], { ...invitations, data: updatedInvitations });
    },
    [invitations, queryClient],
  );

  if (organizationLoading || usersLoading || invitationsLoading) {
    return <Loading />;
  }

  return (
    <StyledDiv>
      <PageHeader ghost={false} title="Settings" breadcrumbRender={backNavigation} />
      <Space direction="vertical" size="middle">
        <Row justify="space-between">
          <Text>
            <b>Organization:</b> {organization.data.name}
            <Button
              onClick={toggleEditOrganizationModal}
              type="link"
              size="small"
              icon={<EditOutlined />}
            />
          </Text>
          <InviteButton onClick={toggleInviteModal} />
        </Row>
        <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabData} />
        {activeTab === SettingsPageTabs.USERS && (
          <UsersTab users={users} organizationId={organization.data.id} />
        )}
        {activeTab === SettingsPageTabs.INVITATIONS && <InvitationsTab invitations={invitations} />}
      </Space>
      <InviteMemberModal
        closeModal={toggleInviteModal}
        visible={showInviteModal}
        onInvite={onInvite}
      />
      <EditOrganizationModal
        closeModal={toggleEditOrganizationModal}
        visible={showOrganizationModal}
        organization={organization}
      />
    </StyledDiv>
  );
}

export default Settings;
