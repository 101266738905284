import { useAuth0 } from '@auth0/auth0-react';
import { Divider, Layout } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import { White } from '../../styles';
import EmailVerification from '../EmailVerification';
import Loading from '../Loading';
import NavBar from '../NavBar';

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  background: ${White};
  margin: auto;
`;

const StyledContent = styled(Content)`
  position: relative;
`;

const StyledDivider = styled(Divider)`
  margin: 0;
  position: sticky;
  top: 64px;
  z-index: 1;
  background: ${White};
`;

function PageLayout({ signupComplete }) {
  const { isLoading, isAuthenticated } = useAuth0();
  const context = useOutletContext();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledLayout>
      <NavBar signupComplete={signupComplete} />
      <StyledDivider />
      {isAuthenticated && signupComplete && <EmailVerification />}
      <StyledContent>
        <Outlet context={context} />
      </StyledContent>
    </StyledLayout>
  );
}

PageLayout.defaultProps = {
  signupComplete: false,
};

PageLayout.propTypes = {
  signupComplete: PropTypes.bool,
};

export default PageLayout;
