import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export default function useRoles() {
  const [roles, setRoles] = useState([]);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const userRoles = user['https://avalancheinsights.com/roles'];
      setRoles(userRoles);
    }
  }, [user, isAuthenticated]);

  return roles;
}
