import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Card, Empty, Layout, Modal, PageHeader, Row, Space, Steps, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCodeFrameThemes, useFinalizeCodeFrame } from '../../api/CodeFrames';
import { useRemapQuestion } from '../../api/Questions';
import { useQuestions } from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import { QCPhase, ThemeEngineQueryKey } from '../../constants';
import useParams from '../../hooks/useParams';
import useSuperuser from '../../hooks/useSuperuser';
import { BlueTitle, LightGray } from '../../styles';
import ThemeDiscovery from './ThemeDiscovery';
import ThemeOrganization from './ThemeOrganization';

const { Content } = Layout;
const { confirm } = Modal;

const StyledContent = styled(Content)`
  padding: 0 24px 24px;
`;

const StyledCard = styled(Card)`
  background-color: ${LightGray};
`;

const StyledQuestionTitle = styled.h3`
  color: ${BlueTitle};
  font-weight: bold;
`;

function ThemeEngine() {
  const isSuperuser = useSuperuser();
  const navigate = useNavigate();
  const { surveyId, questionId, codeFrameId } = useParams();
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState(0);
  const [refetchInterval, setRefetchInterval] = useState(false);

  const { data: question, isLoading: questionLoading } = useQuery(
    ['questions', { surveyId, filters: { question_id: questionId } }],
    useQuestions(),
    {
      select: (data) => data.data[0],
      refetchInterval,
      onSuccess: (data) => {
        if (!refetchInterval && data.qc_phase === QCPhase.REMAPPING) {
          setRefetchInterval(3000);
        } else if (refetchInterval && data.qc_phase !== QCPhase.REMAPPING) {
          queryClient.invalidateQueries({
            queryKey: [ThemeEngineQueryKey.THEMES, { codeFrameId }],
          });
          setRefetchInterval(false);
        }
      },
    },
  );

  const { data: themes, isLoading: themesLoading } = useQuery(
    [ThemeEngineQueryKey.THEMES, { codeFrameId }],
    useCodeFrameThemes(),
    {
      // if theme is a parent set response_ids to all unique child response ids
      select: (data) => ({
        ...data,
        data: data.data.map((theme) =>
          theme.is_parent
            ? {
                ...theme,
                response_ids: [
                  ...new Set(
                    data.data
                      .filter((t) => t.parent_theme_id === theme.id)
                      .flatMap((t) => t.response_ids),
                  ),
                ],
              }
            : theme,
        ),
      }),
    },
  );

  const finalizeCodeFrame = useMutation(useFinalizeCodeFrame());
  const remapQuestion = useMutation(useRemapQuestion());

  const backNavigation = () => (
    <BackButton onClick={() => navigate(`/surveys/${surveyId}/portal`)} text="Back to Dashboard" />
  );

  const finalizeCodeFrameAndRedirect = async () => {
    try {
      await finalizeCodeFrame.mutateAsync({ codeFrameId });
      navigate(`/surveys/${surveyId}?questionId=${questionId}`);
    } catch (error) {
      message.error('Something went wrong');
    }
  };

  const remap = () => {
    confirm({
      title: 'Are you sure you want to Apply Code Frame?',
      content: 'This cannot be undone.',
      okText: 'Apply',
      okType: 'danger',
      onOk: async () => {
        try {
          await remapQuestion.mutateAsync({ questionId });
          setRefetchInterval(3000);
        } catch (error) {
          message.error('Something went wrong');
        }
      },
    });
  };

  const onChange = (index) => {
    setCurrentStep(index);
  };

  if (questionLoading || themesLoading) {
    return <Loading />;
  }

  const steps = [
    {
      title: 'Discover Themes',
      content: <ThemeDiscovery question={question} themes={themes} />,
    },
    {
      title: 'Organize Themes',
      content: themes.data.length ? (
        <ThemeOrganization question={question} themes={themes} />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="You dont have any themes currently. Please find some themes and come back here. "
        />
      ),
    },
  ];

  return (
    <StyledContent>
      <PageHeader ghost={false} breadcrumbRender={backNavigation} />
      <Space direction="vertical" size="middle">
        <StyledCard bordered={false} size="small">
          <Row justify="space-between" wrap={false}>
            <StyledQuestionTitle>{question?.text}</StyledQuestionTitle>
            <Space>
              <Button
                loading={finalizeCodeFrame.isLoading}
                onClick={finalizeCodeFrameAndRedirect}
                size="small"
                disabled={refetchInterval}
              >
                Update & View Theme Explorer
              </Button>
              {isSuperuser && (
                <Button
                  loading={remapQuestion.isLoading}
                  onClick={remap}
                  size="small"
                  disabled={refetchInterval || question.qc_phase !== QCPhase.READY}
                >
                  Apply Code Frame
                </Button>
              )}
            </Space>
          </Row>
          <Steps current={currentStep} onChange={onChange} items={steps} size="small" />
        </StyledCard>
        {refetchInterval ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Remapping, please wait." />
        ) : (
          <div>{steps[currentStep].content}</div>
        )}
      </Space>
    </StyledContent>
  );
}

export default ThemeEngine;
