import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Empty, Row, Space, Spin, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useQuestionSummary } from '../../../api/CodeFrames';
import { useCodeFrames } from '../../../api/Questions';
import { useCreatePersistedSegments, usePersistedSegments } from '../../../api/Surveys';
import Loading from '../../../components/Loading';
import { ChartValueType, MatomoEvent, SegmentType, UserRoleType } from '../../../constants';
import useMatomo from '../../../hooks/useMatomo';
import useParams from '../../../hooks/useParams';
import useRoles from '../../../hooks/useRoles';
import { queryType } from '../../../types';
import ManageThemesModal from '../ManageThemesModal';
import OpenEndedQuestionsSelect from '../OpenEndedQuestionsSelect';
import ChartValueTypeToggle from './ChartValueTypeToggle';
import QuestionSummary from './QuestionSummary';
import RawDataExportModal from './RawDataExportModal';
import SegmentThemesCard from './SegmentThemesCard';
import ThemesChart from './ThemesChart';

function ThemesTab({
  questions,
  questionResponseOptions,
  selectedQuestionId,
  setSelectedQuestionId,
  selectedSegmentQuestionId,
  setSelectedSegmentQuestionId,
  statAssistEnabled,
  setStatAssistEnabled,
  highlightThemeIds,
  setActiveSegmentTypes,
}) {
  const { surveyId } = useParams();
  const { trackPageView, trackEvent } = useMatomo();
  const queryClient = useQueryClient();
  const roles = useRoles();

  const [isThemesModalVisible, setIsThemesModalVisible] = useState(false);
  const [chartValueType, setChartValueType] = useState(ChartValueType.PERCENTAGE);
  const [hiddenThemes, setHiddenThemes] = useState([]);
  const [isExportModalVisible, setExportModalVisible] = useState(false);

  const openEndedQuestions = questions.data.filter((q) => q.has_themes);
  const question = questions.data.find((q) => q.id === selectedQuestionId);

  const { mutate: createPersistedSegments, isLoading: createPersistedSegmentsLoading } =
    useMutation({
      mutationFn: useCreatePersistedSegments(),
      onSettled: async ({ data }, error, variables) => {
        if (error) {
          message.error('Something went wrong creating segments');
        } else {
          await queryClient.setQueryData(['persistedSegments', { surveyId }], (prev) => {
            if (variables.data.some((s) => s.segment_type === SegmentType.ATTRIBUTE)) {
              const allRespondentSegment = prev.data.find(
                (s) => s.segment_type === SegmentType.ALL_RESPONDENTS,
              );
              const customSegments = prev.data.filter((s) => s.segment_type === SegmentType.CUSTOM);
              return {
                ...prev,
                data: [{ ...allRespondentSegment, active: false }, ...customSegments, ...data],
              };
            }
            return { ...prev, data: [...prev.data, ...data] };
          });
        }
      },
    });

  const { data: summary, isFetching: summaryFetching } = useQuery(
    ['questionSummary', { surveyId, codeFrameId: question.code_frame_id }],
    useQuestionSummary(),
    { enabled: !!selectedQuestionId && !!question.code_frame_id },
  );

  const { data: persistedSegments, isLoading: persistedSegmentsLoading } = useQuery(
    ['persistedSegments', { surveyId }],
    usePersistedSegments(),
    {
      select: (data) => {
        const sortSegments = (a, b) => {
          // Sort by ID if the segment types are the same
          if (a.segment_type === b.segment_type) {
            return a.id - b.id;
          }

          // Define custom sorting order
          const order = {
            [SegmentType.ALL_RESPONDENTS]: -2,
            [SegmentType.ATTRIBUTE]: -1,
            [SegmentType.CUSTOM]: 1,
          };

          // Compare based on segment type
          return order[a.segment_type] - order[b.segment_type];
        };
        return data.data.sort(sortSegments);
      },
      onSuccess: async (data) => {
        // if there aren't any segments create the all respondents segment
        if (!data.length) {
          createPersistedSegments({
            surveyId,
            data: [
              {
                active: true,
                segment: undefined,
                segment_type: SegmentType.ALL_RESPONDENTS,
                label: 'All Respondents',
              },
            ],
          });
        }

        // if there is an attribute segment, set the selected segment question to the attribute question
        if (data.some((s) => s.segment_type === SegmentType.ATTRIBUTE)) {
          setSelectedSegmentQuestionId(
            parseInt(
              Object.keys(data.find((s) => s.segment_type === SegmentType.ATTRIBUTE).segment)[0],
              10,
            ),
          );
        }
      },
    },
  );

  const getCodeFrames = useCodeFrames();
  const segmentThemes = useQueries({
    queries:
      persistedSegments?.map((segment) => {
        const params = new URLSearchParams({ only_final: true });
        if (segment.segment) {
          Object.keys(segment.segment).forEach((key) => {
            if (segment.segment[key]?.length) {
              params.append(key, segment.segment[key].join('_$SEPARATOR$_'));
            }
          });
        }
        return {
          queryKey: [
            'codeFrames',
            { questionIds: selectedQuestionId, filters: params.toString(), surveyId, hiddenThemes },
          ],
          queryFn: getCodeFrames,
          enabled: !!selectedQuestionId,
          select: (data) => {
            if (!data.data.length) {
              return { themes: [], total_response_count: 0, segmentId: segment.id };
            }
            return { ...data.data[0].theme_json, segmentId: segment.id };
          },
          cacheTime: 0,
        };
      }) || [],
  });

  useEffect(() => {
    trackPageView('Theme Explorer');
  }, [trackPageView]);

  useEffect(() => {
    if (persistedSegments) {
      setActiveSegmentTypes(persistedSegments.filter((s) => s.active).map((s) => s.segment_type));
    }
  }, [persistedSegments, setActiveSegmentTypes]);

  return (
    <Space direction="vertical" size="large">
      <OpenEndedQuestionsSelect
        openEndedQuestions={openEndedQuestions}
        selectedQuestionId={selectedQuestionId}
        setSelectedQuestionId={setSelectedQuestionId}
        extra={
          !roles.includes(UserRoleType.CLIENT) && [
            <Button key="export" onClick={() => setExportModalVisible(true)} size="small">
              Export raw data
            </Button>,
          ]
        }
      />
      {!question.code_frame_id && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='Themes are not yet finalized. To finalize them, click "Update & View Theme Explorer" located at the top-right of the page in Theme Engine.'
        />
      )}
      {question.code_frame_id && summaryFetching && <Loading />}
      {question.code_frame_id && !summaryFetching && (
        <>
          {summary?.data &&
            (roles.includes(UserRoleType.ADMINISTRATOR) ||
              roles.includes(UserRoleType.EDITOR) ||
              summary.data.summary_generated) && (
              <QuestionSummary question={question} summary={summary.data} />
            )}
          <SegmentThemesCard
            segments={persistedSegments}
            questions={questions.data.filter((q) => q.is_filterable)}
            questionResponseOptions={questionResponseOptions}
            selectedQuestion={question}
            selectedSegmentQuestionId={selectedSegmentQuestionId}
            setSelectedSegmentQuestionId={setSelectedSegmentQuestionId}
            statAssistEnabled={statAssistEnabled}
            setStatAssistEnabled={setStatAssistEnabled}
            createPersistedSegments={createPersistedSegments}
          />
          {segmentThemes.length &&
          !segmentThemes.some((r) => r.isLoading) &&
          !persistedSegmentsLoading &&
          !createPersistedSegmentsLoading ? (
            <>
              <ThemesChart
                question={question}
                activeSegments={persistedSegments?.filter((s) => s.active)}
                segmentThemes={segmentThemes}
                chartValueType={chartValueType}
                hiddenThemes={hiddenThemes}
                highlightThemeIds={
                  statAssistEnabled &&
                  persistedSegments.every((s) =>
                    s.active ? s.segment_type === SegmentType.ATTRIBUTE : true,
                  )
                    ? highlightThemeIds
                    : []
                }
              />
              <Space>
                <ChartValueTypeToggle
                  key="chartValueType"
                  chartValueType={chartValueType}
                  setChartValueType={setChartValueType}
                />
                <Button
                  key="manageThemes"
                  size="small"
                  onClick={() => {
                    trackEvent(MatomoEvent.MANAGE_THEMES_CLICKS);
                    setIsThemesModalVisible(true);
                  }}
                >
                  Manage themes
                </Button>
              </Space>
            </>
          ) : (
            <Row justify="center">
              <Spin size="large" />
            </Row>
          )}
          <ManageThemesModal
            themes={segmentThemes[0]?.data?.themes}
            saveThemes={setHiddenThemes}
            hiddenThemes={hiddenThemes}
            isModalVisible={isThemesModalVisible}
            setIsModalVisible={setIsThemesModalVisible}
            codeFrameId={question.code_frame_id}
            surveyId={surveyId}
          />
        </>
      )}
      <RawDataExportModal
        visible={isExportModalVisible}
        closeModal={() => setExportModalVisible(false)}
        openEndedQuestions={openEndedQuestions.filter((q) => q.code_frame_id)}
        selectedQuestionId={selectedQuestionId}
        surveyId={surveyId}
      />
    </Space>
  );
}

ThemesTab.defaultProps = {
  selectedQuestionId: undefined,
  selectedSegmentQuestionId: undefined,
  highlightThemeIds: [],
};

ThemesTab.propTypes = {
  questions: queryType.isRequired,
  questionResponseOptions: PropTypes.arrayOf(queryType).isRequired,
  selectedQuestionId: PropTypes.number,
  setSelectedQuestionId: PropTypes.func.isRequired,
  selectedSegmentQuestionId: PropTypes.number,
  setSelectedSegmentQuestionId: PropTypes.func.isRequired,
  statAssistEnabled: PropTypes.bool.isRequired,
  setStatAssistEnabled: PropTypes.func.isRequired,
  highlightThemeIds: PropTypes.arrayOf(PropTypes.number),
  setActiveSegmentTypes: PropTypes.func.isRequired,
};

export default ThemesTab;
