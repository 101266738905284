import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { Alert, Button, notification } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useVerifyEmail } from '../../api/Onboarding';

const StickyAlert = styled(Alert)`
  position: sticky;
  top: 65px;
  z-index: 1;
`;

function EmailVerification() {
  const { user } = useAuth0();
  const isEmailVerified = user.email_verified;
  const { mutate: verifyEmail, isLoading: isVerifyEmailLoading } = useMutation(useVerifyEmail());

  const verificationEmailNotification = async () => {
    verifyEmail(
      {},
      {
        onSuccess: (data) => {
          if (data.response.result === 'success') {
            notification.success({
              message: 'Verification email sent',
              description:
                'Please check your email inbox and follow the instructions to verify your email address',
              placement: 'top',
            });
          }
        },
      },
    );
  };

  return (
    <>
      {!isEmailVerified && (
        <StickyAlert
          message="Please verify your email address"
          type="warning"
          banner
          action={
            <Button
              type="link"
              onClick={() => verificationEmailNotification()}
              loading={isVerifyEmailLoading}
            >
              Verify Now
            </Button>
          }
        />
      )}
    </>
  );
}
export default EmailVerification;
