import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export default function useSuperuser() {
  const [isSuperuser, setSuperuser] = useState(null);
  const { user } = useAuth0();

  useEffect(() => {
    const userOrganizationId = user['https://avalancheinsights.com/organizationId'];
    const superuserOrganizationId = parseInt(process.env.REACT_APP_SUPERUSER_ORGANIZATION_ID, 10);
    setSuperuser(userOrganizationId === superuserOrganizationId);
  }, [user]);

  return isSuperuser;
}
